html {
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;
  /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
  margin: 0px;
  /*removes default style*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*enables flex content for its children*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#main {
  width: 100%;
}

.app {
  height: calc(100% - 46px);
  overflow: hidden;
  /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
  margin: 0px;
  padding-left: 12px;
  /*removes default style*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*enables flex content for its children*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.productBody {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.imageWrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.articleImage {
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin: auto;
}

.center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-content {
  max-height: 20em;
  overflow: auto;
}
